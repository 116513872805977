import { get } from '../../utils/request-wrapper'

const { ACTUM_DEFAULT_OIP_HOST } = process.env

const getRecordCall = (
  oipHost: string,
  query: string,
  options: Object<{ after: string, page: number }>
) => {
  return get({
    route: `${oipHost}/artifact/search?q=${query}&after=${options?.after}`,
    params: {
      ...options
    }
  })
}

export default async (
  query: string,
  options: Object<{ after: string, page: number }> = {},
  oipHost: string = ACTUM_DEFAULT_OIP_HOST
) => {
  const recordsResult = await getRecordCall(oipHost, query, options)
  if (recordsResult.count) return recordsResult

  return { count: 0, results: [], total: 0 }
}
