import React from 'react'
import Routes from './Routes'
import RootProvider from '../components/providers/RootProvider'

// export routes, theme provider, redux provider etc.
const Root = () => {
  return <RootProvider>
    <Routes />
  </RootProvider>
}

export default Root
