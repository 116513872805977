import CarbonCountyRegistrar from '../index'
import { SpatialUnit } from '../../../../../classes/Record'

export default function getSpatialAttributes (registrar: CarbonCountyRegistrar, spatialUnitRecord: SpatialUnit) {
  const attributes = []

  const customAttrs = spatialUnitRecord?.artifact?.details?.attrs?.custom
  if (customAttrs) {
    for (const attribute in customAttrs) {
      attributes.push({
        label: `common.${attribute}`,
        value: customAttrs[attribute]
      })
    }
  }

  const order = [
    'Township',
    'Range',
    'Section',
    'City',
    'Subdivision',
    'Block',
    'Lot',
    'Description',
    'Notes',
    'Custom1',
    'Custom2',
    'Custom3',
    'AbstractNumber',
    'SurveyName',
    'LegalMatrix',
    'Page'
  ]
  attributes.sort((a, b) => {
    return order.indexOf(a.label.replace('common.', '')) - order.indexOf(b.label.replace('common.', ''))
  })

  return attributes
}
