import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ArrowRight from '../../../../public/assets/svgs/arrow-right.svg'
import './Registrar.scss'

const RegistrarTile = (props) => {
  const { registrar } = props
  const { t } = useTranslation()

  return (
    <a href={`search${registrar.path}`} className="registrar-tile">
      <div className="registrar-tile__image-container">
        <img className="registrar-tile__image" src={registrar.featuredImage} alt={'Registrar image'} />
        <p className="registrar-tile__name">{t(registrar.tileName)}</p>
      </div>
      <span className="registrar-tile__learn-more">
        {t('links.show_records')}
        <ArrowRight className="registrar-tile__arrow" />
      </span>
    </a>
  )
}

RegistrarTile.propTypes = {
  registrar: PropTypes.object.isRequired
}

export default RegistrarTile
