import type { QueryArg } from '../classes/QueryArg'

const createQueryString = (args: Array<QueryArg>): string => {
  let query = ''
  const AND = 'AND'
  const OR = 'OR'
  const NOT = 'NOT'

  for (let i = 0; i < args?.length; i++) {
    if (i !== 0) query += ' '

    if (args[i].operator) {
      if (args[i].operator.toUpperCase() === AND || args[i].operator.toUpperCase() === OR || args[i].operator.toUpperCase() === NOT) {
        args[i] = args[i].operator.toUpperCase()
        query += `${args[i]}`
      } else if (args[i].operator.toLowerCase() === 'wrap') {
        if (args[i].type === 'start') {
          query += '('
        } else if (args[i].type === 'end') {
          query += ')'
        } else if (args[i].type === 'all') {
          query = query.slice(0, -1) // this is arbitrary--just to standardize return vals
          query = `(${query})`
        }
      } else {
        throw new Error('Provided invalid operator: Options: "AND", "OR", "NOT", "wrap"')
      }
    } else {
      if (args[i].field) {
        query += `${args[i].field}:"${args[i].query}"`
      } else {
        query += `${args[i].query}` // do these need to be wrapped in quotes?
      }
    }
  }
  return query
}

export default createQueryString
