import React, { ReactNode, PropsWithChildren } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Registrar from '../../../classes/Registrar'

import Skeleton from '../Skeleton'
import useRegistrar from '../../../hooks/useRegistrar'
import './SearchResult.scss'

import TenureSearchDetails from './TenureSearchDetails'
import PartySearchDetails from './PartySearchDetails'
import LocationSearchDetails from './LocationSearchDetails'

const SearchResult = (props: PropsWithChildren): ReactNode => {
  const { record } = props
  const registrar: Registrar = useRegistrar(record)
  const history = useHistory()
  const { t } = useTranslation()

  if (!record || !registrar) return <Skeleton />
  const docIdentifier = record.docIdentifier

  const handleViewDetails = () => {
    history.push(`/search${registrar.path}/${docIdentifier}`)
  }

  return (
    <li className="card">
      <motion.div className="search-result" layoutId={record.meta.originalTxid}>
        <TenureSearchDetails registrar={registrar} record={record} />
        <PartySearchDetails registrar={registrar} record={record} />
        <LocationSearchDetails registrar={registrar} record={record} />

        <button
          onClick={handleViewDetails}
          className={'outlined-button location-search-details__button'}
        >
          {t('links.view_details')}
        </button>
      </motion.div>
    </li>
  )
}

SearchResult.propTypes = {
  record: PropTypes.shape({
    artifact: PropTypes.shape({
      info: PropTypes.object
    }).isRequired
  }).isRequired
}

export default SearchResult
