import React, { useState, ChangeEvent } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import './styles.scss'

const Checkbox = props => {
  const { checked: _checked, className, label, onChange, value } = props
  const [checked, setChecked] = useState(_checked)
  const [hover, setHover] = useState(false)

  const handleChange = (e: ChangeEvent<HTMLInputElement>): null => {
    setChecked(e.target.checked)
    onChange(e.target.checked, value)
  }

  return (
    <label className={clsx('checkbox', hover && 'checkbox--hover', className)} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <input
        className="checkbox__input"
        type="checkbox"
        checked={checked}
        onChange={handleChange}
      />
      <div className={clsx('checkbox__box', checked && 'checkbox__box--checked', hover && 'checkbox__box--hover')} />
      <span className="checkbox__label">
        {label}
      </span>
    </label>
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
}

Checkbox.defaultProps = {
  className: '',
  checked: false
}

export default Checkbox
