import React, { useEffect, useState, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Switch, useParams, useHistory } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'

import useOIPSearch from '../../../hooks/mutations/useOIPSearch'
import { useSearchValue } from '../../providers/SearchValueProvider'
import { activeRegistrarKeys } from "../../../helpers";
import onEnter from '../../../helpers/onEnter'
import * as Registrars from '../../../registrars'
import './Search.scss'

import RecordSummary from '../RecordSummary'
import { PoweredBy, SearchInput, RecorderSelect, SearchList, SearchFilter } from '../../library'

const { ACTUM_DEFAULT_OIP_HOST } = process.env
const showSubTypeFilter = false
const registrars = {}

interface FilterOption {
  label: string,
  value: string,
  checked: boolean
}

const registrarKeys = activeRegistrarKeys(Registrars)
registrarKeys.forEach((key, i) => {
  const registrarObject = Registrars[key]
  registrars[registrarObject.path] = registrarObject
})

const registrarLocationBuilder = (locationArray: Array<string>): string =>
  locationArray.reduce((fullLocation, current) => current ? `${fullLocation}/${current}` : fullLocation, '')

const Search = (): ReactNode => {
  const { t } = useTranslation()
  const { region, state, county, id } = useParams()
  const registrar = registrars[registrarLocationBuilder([region, state, county])]
  const filterOptions: Array<FilterOption> = [
    { label: 'Parties', value: 'party', checked: false },
    { label: 'Tenures', value: 'tenure', checked: true },
    { label: 'Spatial Units', value: 'spatialUnit', checked: false }
  ]
  const history = useHistory()
  const [currentPage, setCurrentPage] = useState(0)
  const [currentOIPHost, setCurrentOIPHost] = useState(localStorage.getItem('oipHost') || ACTUM_DEFAULT_OIP_HOST)
  const [paginationMap, setPaginationMap] = useState({ 0: '' })
  const [searchValue, setSearchValue] = useSearchValue()
  const [filter, setFilter] = useState(filterOptions)
  const searchRecords = useOIPSearch(
    searchValue,
    { after: paginationMap[currentPage], page: currentPage },
    registrar,
    filter,
    currentOIPHost
  )

  useEffect(() => {
    if (!registrar?.altHost) {
      setCurrentOIPHost(ACTUM_DEFAULT_OIP_HOST)
      localStorage.setItem('oipHost', ACTUM_DEFAULT_OIP_HOST)
    }
  }, [registrar])

  useEffect(() => {
    if (!localStorage.getItem('oipHost')) localStorage.setItem('oipHost', ACTUM_DEFAULT_OIP_HOST)
  }, [])

  function handlePageChange (newPage: number) {
    const newPaginationMap = { ...paginationMap }
    newPaginationMap[currentPage + 1] = searchRecords?.data?.next
    setPaginationMap(newPaginationMap)
    setCurrentPage(newPage)
  }

  function handleSearch (query: string) {
    setSearchValue(query)
    setCurrentPage(0)
  }

  const runSearch = async () => {
    if (id) history.goBack()
    setCurrentPage(0)
  }

  const handleOIPHostChange = (val: string) => {
    setCurrentOIPHost(val)
    localStorage.setItem('oipHost', val)
  }

  const handleFilterChange = (updatedFilter: FilterOption) => {
    setFilter(updatedFilter)
  }

  return (
    <main id="search-container">

      <section id="sidebar">
        <SearchInput
          id="out"
          value={searchValue}
          onChange={e => handleSearch(e.target.value)}
          onClick={runSearch}
          onKeyDown={onEnter(runSearch)}
        />
        <p>{t('search.optional_fields')}</p>

        <RecorderSelect registrars={registrars} selected={registrar ? registrar.path : 'all'} />

        {showSubTypeFilter && <SearchFilter
          onChange={handleFilterChange}
          options={filter}
        />}
      </section>

      <section id="results-container">

        <AnimatePresence initial={false}>
          <Switch>
            <Route exact path="/search/:region?/:state?/:county?" render={(props) => (
              <SearchList
                records={searchRecords?.data?.results}
                handlePageChange={handlePageChange}
                handleOIPHostChange={handleOIPHostChange}
                total={searchRecords?.data?.total}
                currentPage={currentPage}
                registrar={registrar}
                query={searchValue}
                loading={searchRecords.isLoading}
                {...props}
              />
            )}
            />

            <Route path="/search/:region/:state/:county/:id" render={(props) => (
              <RecordSummary
                id={id}
                registrar={registrar}
                bHasSearchResults={searchRecords?.data?.total > 0}
                {...props}
              />
            )} />

          </Switch>
        </AnimatePresence>

      </section>
      <PoweredBy />
    </main>
  )
}

export default Search
