import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { Navbar } from '../components/library'
import Search from '../components/views/Search'
import Home from './pages/Home'
import Route404 from './pages/Route404'

import '../styles/main.scss'

const Routes = () => {
  const appRoutes = [
    <Route exact path="/" component={Home} />,
    <Route key="search" path="/search/:region?/:state?/:county?/:id?" component={Search} />,
    <Route path="*" component={Route404} />
  ]

  return (
    <div id="app-container">
      <Navbar className="header" />
      <Switch children={appRoutes} className="content" />
    </div>
  )
}

export default Routes
