import TetonCountyRegistrar from '../index'
import { SpatialUnit } from '../../../../../classes/Record'
import type { SearchDetails } from '../../../../../classes/SearchDetails'

export default function getLocationSearchDetails (registrar: TetonCountyRegistrar, spatialUnitRecord: SpatialUnit): Array<SearchDetails> {
  let compactString = ''

  /* See if we should add `Parcel #` */
  const parcelIdentifier = spatialUnitRecord?.artifact?.details?.attrs?.['RCLegal.StatePin']
  if (parcelIdentifier) {
    compactString += parcelIdentifier
  }

  /* See if we should add `Township` */
  const township = spatialUnitRecord?.artifact?.details?.attrs?.['RCLegal.Township']
  if (township) {
    compactString += ` T${township}`
  }

  /* See if we should add `Section` */
  const section = spatialUnitRecord?.artifact?.details?.attrs?.['RCLegal.Section']
  if (section) {
    compactString += ` S${section}`
  }

  /* See if we should add `Range` */
  const range = spatialUnitRecord?.artifact?.details?.attrs?.['RCLegal.Range']
  if (range) {
    compactString += ` R${range}`
  }

  /* See if we should add `Quarter` and QuarterQuarter */
  const quarter = spatialUnitRecord?.artifact?.details?.attrs?.['RCLegal.Quarter.Quarter']
  const quarterQuarter = spatialUnitRecord?.artifact?.details?.attrs?.['RCLegal.QuarterQuarter.Quarter']
  if (quarter) {
    compactString += ` ${quarter}${quarterQuarter}`
  }

  return [{ value: compactString, label: 'common.parcel_#' }]
}
