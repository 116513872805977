import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Select from '../Select'

const RecorderSelect = props => {
  const { registrars, selected } = props
  const history = useHistory()
  const { t } = useTranslation()

  const findRegistrar = ({ displayName, path }: { displayName: string, path: string }) => {
    if (displayName) return Object.values(registrars).find(registrar => t(registrar.displayName) === displayName)
    if (path) return Object.values(registrars).find(registrar => registrar.path === path)
    return null
  }

  const mapRegistrar = (registrar: Object) => {
    if (!registrar) return 'all'
    const displayName = t(registrar.displayName)
    return {
      label: displayName,
      value: registrar.path,
      icon: registrar.logo
    }
  }

  const handleChange = (value: string) => {
    const currentRegistrar = findRegistrar({ displayName: value })
    history.push(`/search${currentRegistrar.path}`)
  }

  const mappedRegistrars = Object.keys(registrars).map(path => mapRegistrar(registrars[path]))

  return (
    <Select
      hasIcons
      options={mappedRegistrars}
      selected={selected}
      handleChange={handleChange}
      nullValue={{
        label: t('common.all_registrars'),
        onSelect: () => history.push('/search')
      }}
    />
  )
}

RecorderSelect.propTypes = {
  registrars: PropTypes.object.isRequired,
  selected: PropTypes.string
}

RecorderSelect.defaultProps = {
  selected: 'all'
}

export default RecorderSelect
