import dayjs from 'dayjs'
import TetonCountyRegistrar from '../index'
import { Tenure } from '../../../../../classes/Record'

export default function getTenureAttributes (registrar: TetonCountyRegistrar, oipTenure: Tenure, compact?: boolean) {
  const attributes = []

  /* See if we should add `Document Type` */
  const documentType = oipTenure?.artifact?.storage?.files?.[0]?.type
  if (documentType) {
    attributes.push({
      label: 'common.document_type',
      value: documentType
    })
  }

  /* See if we should add `Executed On` */
  const executedOn = oipTenure?.artifact?.details?.attrs?.['RCRecorder.DateOfInstrument']
  if (executedOn) {
    attributes.push({
      label: 'common.executed_on',
      value: dayjs(executedOn).format('DD/MMM/YYYY')
    })
  }

  /* See if we should add `Recorded On` */
  const recordedOn = oipTenure?.artifact?.details?.attrs?.['RCRecorder.DateTimeOfFiling']
  if (recordedOn) {
    attributes.push({
      label: 'common.recorded_on',
      /* @TODO Add Timezones! */
      value: dayjs(recordedOn).format('DD/MMM/YYYY H:mm:ss')
    })
  }

  return compact ? attributes.slice(0, 6) : attributes
}
