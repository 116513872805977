import React, { PropsWithChildren, ReactNode } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

import './RegistrarHeader.scss'
import Registrar from '../../../classes/Registrar'

const RegistrarHeader = (props: PropsWithChildren): ReactNode => {
  const registrar: Registrar = props.registrar
  const { t } = useTranslation()
  const headerName: string = t(registrar?.headerName)

  return (
    <div className={clsx('registrar-header', props.className)}>
      <motion.img
        className="registrar-header__logo"
        key={registrar.logo}
        src={registrar.logo}
        {...props.motionConfig}
      />
      <motion.h2
        className="registrar-header__name"
        key={registrar.headerName}
        layerId="record-header"
        {...props.motionConfig}
      >
        {headerName}
      </motion.h2>
    </div>
  )
}

RegistrarHeader.propTypes = {
  className: PropTypes.string,
  motionConfig: PropTypes.object,
  registrar: PropTypes.object.isRequired
}

RegistrarHeader.defaultProps = {
  motionConfig: {}
}

export default RegistrarHeader
