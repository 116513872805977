import React, { PropsWithChildren, ReactNode } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import './SecondaryCard.scss'

const SecondaryCard = (props: PropsWithChildren): ReactNode => {
  const { className, children } = props

  return (
    <div className={clsx('secondary-card', className)}>
      {children}
    </div>
  )
}

SecondaryCard.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
}

export default SecondaryCard
