import i18next from 'i18next'
import NewYorkCityRegistrar from '../index'
import NewYorkCitySpatialUnit from '../classes/NewYorkCitySpatialUnit'
import type { SearchDetails } from '../../../../../classes/SearchDetails'

export default function getLocationSearchDetails (registrar: NewYorkCityRegistrar, spatialUnitRecord: NewYorkCitySpatialUnit): Array<SearchDetails> {
  const detailsToReturn = []
  const customProperties = spatialUnitRecord?.customProperties

  if (customProperties) {
    const { BOROUGH, BLOCK, LOT } = customProperties
    if (BOROUGH || BLOCK || LOT) detailsToReturn.push({ value: `${BOROUGH} ${BLOCK} ${LOT}`, label: 'common.bbl' })

    const { STREET_NAME, STREET_NUMBER, ADDR_UNIT } = customProperties
    STREET_NAME && detailsToReturn.push({ value: `${STREET_NAME} ${STREET_NUMBER}${ADDR_UNIT && `, ${i18next.t('common.UnitNumber')}: ${ADDR_UNIT}`}`, label: 'common.address' })
  }

  return detailsToReturn
}
