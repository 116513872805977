import React from 'react'
import * as PropTypes from 'prop-types'
import ArrowLeft from '../../../../public/assets/svgs/arrow-left.svg'
import ArrowRight from '../../../../public/assets/svgs/arrow-right.svg'
import './Pagination.scss'

const Pagination = ({
  currentPage,
  handlePageChange,
  rowsPerPage,
  total,
  loading
}) => {
  const maxPage = Math.ceil(total / rowsPerPage)
  // const prevDisabled = page === 0
  // const nextDisabled = page === pages - 1
  const prevDisabled = currentPage === 0
  const nextDisabled = currentPage === maxPage - 1 || loading

  function handlePrev () {
    if (prevDisabled) return
    const newPage = currentPage - 1
    handlePageChange(newPage)
    // handlePaginatedSearch({
    //   page: newPage
    // })
    // setPage(newPage)
  }

  function handleNext () {
    if (nextDisabled) return
    // console.log('next: ', page, paginationMap[page])
    const newPage = currentPage + 1
    handlePageChange(newPage)
    // handlePaginatedSearch({
    //   after: paginationMap[page],
    //   page: newPage
    // })
    // setPage(newPage)
  }

  return <div id='pagination-container'>
    <div id='record-count-container'>
      <p style={{ marginRight: 10 }}>Records Found: </p>
      <p style={{ fontWeight: 700 }}>{total}</p>
    </div>
    <div id='pagination-buttons'>
      <button className='pagination_button' disabled={prevDisabled} onClick={handlePrev} ><ArrowLeft/></button>
      <p>{`${currentPage + 1} of ${maxPage}`}</p>
      <button className='pagination_button' disabled={nextDisabled} onClick={handleNext} ><ArrowRight/></button>
    </div>
  </div>
}

Pagination.propTypes = {
  searchResult: PropTypes.shape({
    count: PropTypes.number,
    total: PropTypes.number,
    results: PropTypes.array,
    next: PropTypes.string
  }),
  className: PropTypes.string,
  style: PropTypes.object
}

Pagination.defaultProps = {
  searchResult: {}
}

export default Pagination
