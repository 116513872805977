import type { RecordInterface } from './interfaces/Record.interface'

export default class Record implements RecordInterface {
  constructor (passedRecord: Object) {
    this.#mapKeys(passedRecord)
  }

  #mapKeys = (record: Object) => {
    if (!record) return

    Object.keys(record).forEach(key => {
      this[key] = record[key]
    })
  }

  getArtifactProperty = (key: string): string => {
    return this.artifact?.[key]
  }
}
