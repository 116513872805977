import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import './RecorderLink.scss'

const RecorderLink = props => {
  const { registrar } = props
  const { t } = useTranslation()

  if (!registrar) {
    return <div>Unknown Registrar</div>
  }

  return <a
    href={registrar.url}
    target="_blank"
    className="recorder-link"
  >
    <img src={registrar.logo} alt="recorder logo" />
    {t(registrar.recorderName)}
  </a>
}

RecorderLink.propTypes = {
  registrar: PropTypes.object.isRequired
}

export default RecorderLink
