import TetonCountyRegistrar from '../index'
import { SpatialUnit } from '../../../../../classes/Record'

export default function getSpatialAttributes (registrar: TetonCountyRegistrar, spatialUnitRecord: SpatialUnit) {
  const attributes = []

  /* See if we should add `Parcel #` */
  const parcelIdentifier = spatialUnitRecord?.artifact?.details?.attrs?.['RCLegal.StatePin']
  if (parcelIdentifier) {
    attributes.push({
      label: 'common.parcel_identifier',
      value: parcelIdentifier
    })
  }

  /* See if we should add `Township` */
  const township = spatialUnitRecord?.artifact?.details?.attrs?.['RCLegal.Township']
  if (township) {
    attributes.push({
      label: 'common.township',
      value: township
    })
  }

  /* See if we should add `Section` */
  const section = spatialUnitRecord?.artifact?.details?.attrs?.['RCLegal.Section']
  if (section) {
    attributes.push({
      label: 'common.section',
      value: section
    })
  }

  /* See if we should add `Range` */
  const range = spatialUnitRecord?.artifact?.details?.attrs?.['RCLegal.Range']
  if (range) {
    attributes.push({
      label: 'common.range',
      value: range
    })
  }

  /* See if we should add `Quarter` and QuarterQuarter */
  const quarter = spatialUnitRecord?.artifact?.details?.attrs?.['RCLegal.Quarter.Quarter']
  const quarterQuarter = spatialUnitRecord?.artifact?.details?.attrs?.['RCLegal.QuarterQuarter.Quarter']
  if (quarter) {
    attributes.push({
      label: 'common.quarter',
      value: `${quarter}${quarterQuarter}`
    })
  }

  return attributes
}
