import React, { PropsWithChildren, ReactNode } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import './Divider.scss'

const Divider = (props: PropsWithChildren): ReactNode => {
  return (
    <hr className={clsx('divider', props.className)} />
  )
}

Divider.propTypes = {
  className: PropTypes.string
}

export default Divider
