import Record from './Record'
import type { TenureInterface } from './interfaces/Tenure.interface'

export default class Tenure extends Record implements TenureInterface {
  getCustomProperty = (key: string): string | Array<string> => {
    return this.artifact?.details?.attrs?.custom?.[key]
  }

  get docIdentifier (): string {
    const title = this.artifact?.info?.title?.trim()

    if (title && title !== 'DOC_ID') return this.artifact?.info?.title?.trim()

    return this.meta.originalTxid
  }

  get parties (): Array<{ party: string, role: string }> {
    return this.artifact?.details?.parties
  }
}
