import { useEffect, useState } from 'react'
import * as registrars from '../registrars'

import Registrar from '../classes/Registrar'
import Record from '../classes/Record'

const useRegistrar = (record: Record): Registrar => {
  const [registrar, setRegistrar] = useState(undefined)

  useEffect(() => {
    for (const recorder in registrars) {
      const registrarI: Registrar | Object = registrars[recorder]

      if (registrarI.namespace === record?.artifact?.details?.ns) {
        return setRegistrar(registrarI)
      }
    }
  }, [record])

  return registrar
}

export default useRegistrar
