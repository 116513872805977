import React, { ReactNode, PropsWithChildren, useEffect, useState } from 'react'
import { Divider, DocumentSummary, LabeledList, LocationList } from '../../library'
import './RecordDetails.scss'

import Record, { Party } from '../../../classes/Record'
import Registrar from '../../../classes/Registrar'
import type { PartyGroup } from '../../../classes/PartyGroup'

export default function RecordDetails (props: PropsWithChildren): ReactNode {
  const record: Record = props.record
  const registrar: Registrar = props.registrar
  const [partyRoles: Array<PartyGroup>, setPartyRoles: Function] = useState([])
  const locations = record?.artifact?.details?.spatialUnits

  useEffect(async () => {
    const roles: Array<PartyGroup> = await registrar.getPartyRoles(record)
    setPartyRoles(roles)
  }, [record])

  return (
    <div className="record-details">
      <DocumentSummary record={record} registrar={registrar} />

      {partyRoles.map((group: PartyGroup, i: number) => (
        !!group.parties.length && (
          <React.Fragment key={`party-group-${i}`}>
            <Divider />
            <LabeledList
              className="record-details__party-group"
              label={group.label}
              values={group.parties.map((party: Party) => registrar.getPartyName(party))}
            />
          </React.Fragment>
        )
      ))}

      {locations && <LocationList locations={locations} registrar={registrar} />}
    </div>
  )
}
