import React from 'react'
import { Skeleton } from '../../../library'
import './LoadingCard.scss'

const LoadingCard = () => {
  return (
    <div className="loading-card">
      {[...Array(16).keys()].map(x => <Skeleton key={`skeleton-${x}`} />)}
    </div>
  )
}

export default LoadingCard
