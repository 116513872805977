import CarbonCountyRegistrar from '../index'
import { Tenure } from '../../../../../classes/Record'

export default function getTenureAttributes (registrar: CarbonCountyRegistrar, oipTenure: Tenure, compact?: boolean) {
  const attributes = []

  const customAttrs = oipTenure?.artifact?.details?.attrs?.custom
  if (customAttrs) {
    for (const attribute in customAttrs) {
      if (attribute === 'Fees') {
        attributes.push({
          label: `common.${attribute}`,
          value: `$${(JSON.parse(customAttrs[attribute])).Total}`
        })
        continue
      }

      attributes.push({
        label: `common.${attribute}`,
        value: customAttrs[attribute]
      })
    }
  }

  const order = [
    'Related',
    'Type',
    'Notes',
    'DocumentDate',
    'ValidatedDate',
    'ReturnDate',
    'ReturnTo',
    'ReturnAddress1',
    'ReturnAddress2 ',
    'ReturnCity',
    'ReturnState',
    'ReturnZip',
    'MortgageAmount',
    'Consideration',
    'Fees',
    'Pages',
    'Description',
    'Department',
    'ReleasedBy',
    'BookType',
    'Book',
    'Page',
    'VolCert',
    'FileNumber',
    'Restricted'
  ]

  const mappedAttributes = attributes.map(attribute => {
    if (attribute.label === 'common.Related') {
      return ({
        ...attribute,
        value: JSON.parse(attribute.value)
      })
    }
    return attribute
  })

  const sortedAttributes = mappedAttributes.sort((a, b) => {
    return order.indexOf(a.label.replace('common.', '')) - order.indexOf(b.label.replace('common.', ''))
  })

  return compact ? sortedAttributes.slice(0, 6) : sortedAttributes
}
