import React from 'react'
import { useTranslation } from 'react-i18next'

import FLO from '../../../../public/assets/images/flo.svg'
import OIP from '../../../../public/assets/images/oip.svg'
import MLG from '../../../../public/assets/images/mlg-logo.png'
import './PoweredBy.scss'

export default function PoweredBy () {
  const { t } = useTranslation()

  return <footer>
      <p>{t('home.powered_by')}</p>
      <a href='https://flo.cash'><FLO/></a>
      <a href='https://mediciland.com'><img src={MLG}/></a>
      <a href='https://oip.io'><OIP/></a>
  </footer>
}
