import { useEffect, useState } from 'react'

import { getRecord } from '../helpers'
import { Record, SpatialUnit, Party, Tenure } from '../classes/Record'

export default function useOIPRecord (txid: string): SpatialUnit | Party | Tenure | Record | null {
  const [
    record: SpatialUnit | Party | Tenure | Record,
    setRecord: Function
  ] = useState(null)

  useEffect(() => {
    async function loadRecord () {
      const mappedRecord = await getRecord(txid)
      setRecord(mappedRecord)
    }

    loadRecord()
  }, [txid])

  if (!txid) return null
  return record
}
