import Registrar from '../classes/Registrar'
import type { QueryArg } from '../classes/QueryArg'

export default function createOIPTenureQuery (query: string, registrar: Registrar | Object, filter: Array<{ label: string, value: string, checked: boolean }>): Array<QueryArg> {
  // This function creates an array with all checked filter options and OR operators
  const setFilterQuery = (f: Array<{ label: string, value: string, checked: boolean }>): Array => {
    const hasActiveFilter = f && f.map(option => option.checked).includes(true)

    if (hasActiveFilter) {
      return [
        { operator: 'AND' },
        { operator: 'wrap', type: 'start' },
        ...f.reduce((mappedFilters, option) => {
          if (option.checked) return [...mappedFilters, mappedFilters.length !== 0 && { operator: 'OR' }, { field: 'artifact.subtype', query: option.value }].filter(falsy => falsy)
          return mappedFilters
        }, []),
        { operator: 'wrap', type: 'end' }
      ]
    }
    return []
  }

  return [
    { operator: 'wrap', type: 'start' },
    { query: query },

    // subtype filters
    ...setFilterQuery(filter),

    // Registrar filter
    { operator: 'AND' },
    { field: 'meta.latest', query: true },
    registrar && { operator: 'AND' },
    registrar && { field: 'artifact.details.ns', query: registrar.namespace },
    { operator: 'wrap', type: 'end' }
  ].filter(falsy => falsy)
}
