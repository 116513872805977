import React, { Fragment, PropsWithChildren, ReactNode } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Registrar from '../../../classes/Registrar'
import { LocationSummary, Divider, SecondaryCard } from '../index.js'

const LocationList = (props: PropsWithChildren): ReactNode => {
  const locations: Array<string> = props.locations
  const registrar: Registrar = props.registrar
  const { t } = useTranslation()

  return (
    <SecondaryCard>
      <h4 style={{ marginTop: 0 }}>{t('location.location_information')}</h4>
      {locations && locations.map((txid: string, i: number): ReactNode =>
        <Fragment key={`location-${txid}-${i}`}>
          {i > 0 && <Divider />}
          <LocationSummary txid={txid} registrar={registrar} />
        </Fragment>
      )}
    </SecondaryCard>
  )
}

LocationList.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.string).isRequired,
  registrar: PropTypes.object.isRequired
}

export default LocationList
