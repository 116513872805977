import React from 'react'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'

import './FeaturedRegistrar.scss'

const FeaturedRegistrar = (props) => {
  const { registrar } = props
  const { t } = useTranslation()

  const animationConfig = {
    initial: { x: 200, opacity: 0 },
    animate: { x: 0, opacity: 1 },
    transition: { ease: 'easeOut', duration: 0.3 },
    exit: { x: -300, opacity: 0 }
  }

  return (
    <motion.div
      key={`registrar-tile-${registrar.displayName}`}
      {...animationConfig}
      className="featured-registrar">
      <div
        className="featured-registrar__image-container">
        <img className="featured-registrar__image" src={registrar.featuredImage} alt="Image of featured registrar" />
      </div>
      <div {...animationConfig} key={`registrar-summary-${registrar.displayName}`} className="featured-registrar__summary">
        <h3 className="featured-registrar__summary-title">{t(registrar.featuredName)}</h3>
        <div className="featured-registrar__summary-description">{t(registrar.description)}</div>
        <a className="featured-registrar__link" href={registrar.mlgURL} target="_blank">{`${t('search.learn_more')} ${t(registrar.featuredLink)}`}</a>
      </div>
    </motion.div>
  )
}

FeaturedRegistrar.propTypes = {
  registrar: PropTypes.object
}

export default FeaturedRegistrar
