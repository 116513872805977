import React from 'react'
import LoadingCard from '../LoadingCard'
import './LoadingList.scss'

const LoadingList = () => {
  return (
    <div className="loading-list">
      {[...Array(4).keys()].map(x => <LoadingCard key={x} />)}
    </div>
  )
}

export default LoadingList
