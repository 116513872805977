import Registrar from '../../../../classes/Registrar'
import NewYorkCityTenure from './classes/NewYorkCityTenure'
import NewYorkCityParty from './classes/NewYorkCityParty'
import NewYorkCitySpatialUnit from './classes/NewYorkCitySpatialUnit'

import getTenureAttributes from './helpers/getTenureAttributes'
import getLocationSearchDetails from './helpers/getLocationSearchDetails'
import getDocumentLink from './helpers/getDocumentLink'
import getSpatialAttributes from './helpers/getSpatialAttributes'
import getPartyRoles from './helpers/getPartyRoles'

import NYLogo from './assets/logo.png'
import NYFeaturedImage from './assets/featured-image.png'

export default class NewYorkCityRegistrar extends Registrar {
  constructor () {
    super()

    // Constants
    this.floAddress = 'FByDstiykihshgimEBNPrdDBL5gg9iriHZ'
    this.path = '/usa/ny/nyc'
    this.mlgURL = 'https://mediciland.com'
    this.url = 'https://www.nyc.gov/'
    this.namespace = 'nyc.dof/1.0.0'

    // Copy
    this.tileName = 'usa.ny.nyc.tileName'
    this.displayName = 'usa.ny.nyc.displayName'
    this.featuredName = 'usa.ny.nyc.featuredName'
    this.featuredLink = 'usa.ny.nyc.featuredLink'
    this.headerName = 'usa.ny.nyc.headerName'
    this.recorderName = 'usa.ny.nyc.recorderName'
    this.description = 'usa.ny.nyc.description'
    this.logo = NYLogo
    this.featuredImage = NYFeaturedImage

    // Class bindings
    this.Tenure = NewYorkCityTenure
    this.Party = NewYorkCityParty
    this.SpatialUnit = NewYorkCitySpatialUnit

    // Overrides
    this.altHost = {
      floAddress: process.env.ACTUM_USA_NY_FLO_ADDRESS_ALT,
      floExplorer: process.env.ACTUM_USA_NY_FLO_EXPLORER_ALT,
      oipd: process.env.ACTUM_USA_NY_OIP_HOST_ALT
    }
  }

  // Helper Functions to extract information specific to the Registrar
  getTenureAttributes = (tenure: NewYorkCityTenure, compact?: boolean) => getTenureAttributes(this, tenure, compact)
  getLocationSearchDetails = (spatialUnit: NewYorkCitySpatialUnit) => getLocationSearchDetails(this, spatialUnit)
  getSpatialAttributes = (spatialUnit: NewYorkCitySpatialUnit) => getSpatialAttributes(this, spatialUnit)
  getDocumentLink = (tenure: NewYorkCityTenure, type?: string) => getDocumentLink(this, tenure, type)
  getPartyRoles = (tenure: NewYorkCityTenure) => getPartyRoles(this, tenure)
}
