import Registrar from '../index'
import { Tenure, Party } from '../../Record'
import { getRecord } from '../../../helpers'

export default async (registrar: Registrar, tenure?: Tenure): Array<{ label: string, parties: Array }> => {
  const partyIds = tenure?.parties
  const groups = {}
  if (!partyIds) return []

  const mappedParties = await Promise.all(partyIds.map(async ({ party: partyId }) => getRecord(partyId)))

  mappedParties.forEach((party: Party) => {
    if (!groups[party.role]) groups[party.role] = { label: party.role?.toLowerCase(), parties: [] }
    groups[party.role].parties.push(party)
  })

  // Return Party Roles
  return Object.values(groups)
}
