import React, { PropsWithChildren, ReactNode } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import './DetailRow.scss'
import { is } from 'ramda'

export default function DetailRow (props: PropsWithChildren): ReactNode {
  const { action, className, label, value: _value, span, empty } = props
  const { t } = useTranslation()

  const valueToRender = (value: Array | number | string | ReactNode): ReactNode => {
    if (is(Array, value)) return value.map((val, i) => <span key={`detail-${label}-row-${i}`} className="detail-row__value-row">{val}</span>)
    return value
  }

  return (
    <div className={clsx('detail-row', span && 'detail-row--full-width', action && 'detail-row--has-action', className)}>
      {!empty && <>
        <label className="detail-row__label">{t(label)}</label>
        <span className={clsx('detail-row__value', action && 'detail-row__value--has-action', !_value && 'skeleton')}>{valueToRender(_value)}</span>

        {action && <span className="detail-row__action-container">{action}</span>}
      </>}
    </div>
  )
}

DetailRow.defaultProps = {
  label: 'common.no_label',
  empty: false,
  span: false
}

DetailRow.propTypes = {
  action: PropTypes.node,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.number, PropTypes.string, PropTypes.node]),
  label: PropTypes.string,
  empty: PropTypes.bool,
  span: PropTypes.bool
}
