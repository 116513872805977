import React from 'react'
import ReactDOM from 'react-dom'
import initTranslation from './i18n'
import Root from './App/Root'
import './styles/theme/theme.scss'

const { NODE_ENV, ACTUM_DEFAULT_OIP_HOST } = process.env

initTranslation()
if (NODE_ENV === 'production') localStorage.setItem('oipHost', ACTUM_DEFAULT_OIP_HOST)

ReactDOM.render(
  <Root />,
  document.getElementById('root')
)
