import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'

import './Select.scss'
import ArrowUp from '../../../../public/assets/svgs/arrow-up.svg'
import Option from './Option'

const Select = props => {
  const { className, optionListClassName, options, selected, handleChange, nullValue, hasIcons } = props
  const node = useRef()
  const [opened, setOpened] = useState(false)
  const selectedOption = options.find(option => option.value === selected)

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    return () => document.removeEventListener('mousedown', handleClick)
  }, [])

  const handleClick = (e: Object<{ target: Object }>) => {
    if (node.current.contains(e.target)) return // inside click
    setOpened(false) // outside click
  }

  const onChange = (value: string) => {
    setOpened(false)
    handleChange(value)
  }

  const onSelectNullValue = () => {
    setOpened(false)
    nullValue.onSelect()
  }

  const optionCount = options?.length + (nullValue ? 1 : 0)

  return (
    <div
      className={clsx('select', className)}
      ref={node}
    >
      <button
        className="select__button"
        onClick={() => setOpened(!opened)}
      >
        {selected === 'all' && nullValue && <p>{nullValue.label}</p>}
        {selected !== 'all' && (
          <>
            {selectedOption?.icon && <img className="select__icon" src={selectedOption?.icon} alt={`${selectedOption?.label} logo`} />}
            <span className="select__label">{selectedOption?.label}</span>
          </>
        )}

        <motion.span className="select__arrow" animate={{ rotate: opened ? 0 : 180 }}>
          <ArrowUp />
        </motion.span>
      </button>

      <AnimatePresence>
        {opened && (
          <motion.ul
            className={clsx('select__option-list', optionListClassName)}
            initial={{ maxHeight: '0px' }}
            animate={{ maxHeight: `${optionCount * 56}px` }}
            exit={{ maxHeight: '0px', opacity: 0 }}
          >
            {nullValue && <Option value="all" onClick={onSelectNullValue} label={nullValue.label} dropdownHasIcons={hasIcons} />}

            {options && options.map((option, i) => {
              return <Option key={`select-option-${i}`} value={option.label} onClick={onChange} icon={option.icon} label={option.label} dropdownHasIcons={hasIcons} />
            })}
          </motion.ul>
        )}
      </AnimatePresence>

    </div>
  )
}

Select.propTypes = {
  className: PropTypes.string,
  optionListClassName: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    icon: PropTypes.node
  })).isRequired,
  selected: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  nullValue: PropTypes.shape({
    label: PropTypes.string,
    onSelect: PropTypes.func.isRequired
  }),
  hasIcons: PropTypes.bool
}

Select.defaultProps = {
  className: '',
  optionListClassName: '',
  selected: 'all',
  hasIcons: false
}

export default Select
