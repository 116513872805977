import React from 'react'
import { motion } from 'framer-motion'

import { Skeleton } from '../../../library'

const LoadingCard = props => {
  return (
    <motion.div id="record-root" className="card record-loading" {...props}>
      <div className="record-loading-header">
        {[...Array(3).keys()].map(x => <Skeleton key={x} />)}
      </div>
      <div className="record-loading-body">
        <Skeleton />
        <Skeleton />
        <hr />
        <Skeleton />
        <Skeleton />
        <hr />
        {[...Array(14).keys()].map(x => <Skeleton key={x} />)}
      </div>
      <div className="record-loading-footer">
        <Skeleton style={{ height: '100%' }} />
      </div>

    </motion.div>
  )
}

export default LoadingCard
