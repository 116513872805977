import React, { useEffect, useState, PropsWithChildren, ReactNode } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import DetailRow from '../DetailRow'
import Skeleton from '../Skeleton'

import { getRecord } from '../../../helpers'
import Record, { Party } from '../../../classes/Record'

const { ACTUM_DEFAULT_OIP_HOST, ACTUM_DEFAULT_FLO_EXPLORER } = process.env

const BlockchainProof = (props: PropsWithChildren): ReactNode => {
  const registrar = props.registrar
  const [record: Record, setRecord] = useState(props.record)
  const { t } = useTranslation()
  const oipHost = localStorage.getItem('oipHost')

  useEffect(async () => {
    if (typeof props.record === 'string') {
      const rec: Record = await getRecord(props.record)
      return setRecord(rec)
    }
    setRecord(props.record)
  }, [props.record])

  if (typeof record !== 'object') return <><Skeleton /><br /><Skeleton /></>

  const getHost = () => {
    if (ACTUM_DEFAULT_OIP_HOST !== oipHost) return registrar.altHost?.floExplorer || ACTUM_DEFAULT_FLO_EXPLORER
    if (registrar?.floExplorer) return registrar.floExplorer
    return ACTUM_DEFAULT_FLO_EXPLORER
  }

  const title = record instanceof Party ? record?.artifact?.info?.title : null
  const dateOfConfirmation = dayjs(record.artifact.timestamp).format('DD/MMM/YYYY H:mm:ss')

  return (
    <div>
      {title && <h4>{title}</h4>}
      <DetailRow label={'blockchain.date_of_confirmation'} value={dateOfConfirmation} sizing="secondary" />
      <DetailRow label={'blockchain.transaction_id'} value={record.meta.txid} sizing="secondary" />
      <a href={`${getHost()}/tx/${record.meta.txid}`}>{t('blockchain.view_blockchain_link')}</a>
    </div>
  )
}

BlockchainProof.propTypes = {
  record: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  registrar: PropTypes.object
}

export default BlockchainProof
