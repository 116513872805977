import React, { PropsWithChildren, ReactNode } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import './LabeledList.scss'

const LabeledList = ({ label, values, className }: PropsWithChildren): ReactNode => {
  const { t } = useTranslation()
  const idPrefix = label.replace('common.', '')

  return (
    <div className={clsx('labeled-list', className)}>
      <label className="labeled-list__label">{t(label)}</label>
      <div>
        {values.map((value, i) => <p key={`${idPrefix}-${i}`}>{value}</p>)}
      </div>
    </div>
  )
}

LabeledList.defaultProps = {
  label: '',
  values: []
}

LabeledList.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  values: PropTypes.array
}

export default LabeledList
