import React, { PropsWithChildren, ReactNode, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TabController, BlockchainProof, DetailRow } from '../../library'
import './BlockchainProofs.scss'

import Registrar from '../../../classes/Registrar'
import RwandaRegistrar from '../../../registrars/rwanda'
import { Tenure, Party } from '../../../classes/Record'
import type { PartyGroup } from '../../../classes/PartyGroup'

function padZeros (number) {
  if (number < 10) return `000${number}`
  if (number >= 10 && number < 100) return `00${number}`
  if (number >= 100 && number < 1000) return `0${number}`
  if (number >= 1000) return `${number}`
}

const BlockchainProofs = (props: PropsWithChildren): ReactNode => {
  const record: Tenure = props.record
  const registrar: Registrar = props.registrar
  const [partyRoles: Array<PartyGroup>, setPartyRoles: Function] = useState([])
  const { t } = useTranslation()
  const tabs = []

  useEffect(async () => {
    const roles: Array<PartyGroup> = await registrar.getPartyRoles(record)
    setPartyRoles(roles)
  }, [record])

  // Documents
  const documents = record?.artifact?.storage?.files
  documents && tabs.push({
    label: 'common.documents',
    count: documents?.length,
    content: <>
      <h3>{t('common.documents')}</h3>
      {
        documents?.map((doc, i) => <DetailRow
          key={`document-${i}`}
          label={`${record?.artifact?.info?.title}-${padZeros(i + 1)}${doc.type === 'Image/tiff' ? '.tif' : ` ${doc.type}`}`}
          value={doc.location}
        />)
      }
    </>
  })

  // Parties
  for (const role of partyRoles) {
    role.parties.length && tabs.push({
      label: role.label,
      count: role.parties.length,
      content: <>
        <h3 className="blockchain-proofs__heading">{t(role.label)}</h3>
        {role?.parties?.map((party: Party, i) => <React.Fragment key={`party-role-${i}`}>
          {i > 0 && <hr style={{ margin: '24px 0px' }} />}
          <BlockchainProof type="party" record={party} registrar={registrar} />
        </React.Fragment>)}
      </>
    })
  }

  // Spatial Units
  const spatialUnits = record?.artifact?.details?.spatialUnits
  spatialUnits && tabs.push({
    label: 'common.locations',
    count: spatialUnits?.length,
    content: <>
      <h3>{t('common.locations')}</h3>
      {spatialUnits?.map((loc, i) => <React.Fragment key={`spatial-unit-${i}`}>
        {i > 0 && <hr style={{ margin: '24px 0px' }} />}
        <BlockchainProof record={loc} registrar={registrar} />
      </React.Fragment>)}
    </>
  })

  // TODO: Remove registrar specific sections from this file
  // Registrar Approval - Rwanda only
  if (registrar instanceof RwandaRegistrar) {
    const registrarApproval = record?.meta?.txid
    registrarApproval && tabs.push({
      label: 'rwanda.registrarApproval',
      count: 1,
      content: (
        <>
          <h3>{t('rwanda.registrarApproval')}</h3>
          <BlockchainProof record={registrarApproval} registrar={registrar} />
        </>
      )
    })
  }

  // Fallback if no proof is available
  if (!tabs.length) tabs.push({})

  return (
    <TabController variant="vertical" className="secondary-card" tabs={tabs} layout="layout-2" />
  )
}

export default BlockchainProofs
