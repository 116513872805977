import React from 'react'
import mlgLogo from '../../../../public/assets/images/mlg-logo.png'
import { useHistory } from 'react-router'
import './Navbar.scss'

const { NODE_ENV, ACTUM_DEFAULT_OIP_HOST } = process.env

const Navbar = () => {
  const history = useHistory()

  const goHome = () => {
    history.push('/')
    localStorage.setItem('oipHost', ACTUM_DEFAULT_OIP_HOST)
  }

  return (
    <nav className="navbar">
      <div className="navbar__logo-container">
        <img className="navbar__logo" src={mlgLogo} alt="Logo" onClick={goHome} />
      </div>
      {NODE_ENV === 'development' && <div className="navbar__environment">DEVELOPMENT</div>}
    </nav>
  )
}

export default Navbar
