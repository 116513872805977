import React, { PropsWithChildren, ReactNode } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { DetailRow, RecorderLink } from '../../index'
import { getValueFromAttribute } from '../../../../helpers'
import Registrar from '../../../../classes/Registrar'
import { Tenure } from '../../../../classes/Record'
import './TenureSearchDetails.scss'

import type { DocumentLink } from '../../../../classes/DocumentLink'
import type { SearchDetails } from '../../../../classes/SearchDetails'

const TenureSearchDetails = (props: PropsWithChildren): ReactNode => {
  const registrar: Registrar = props.registrar
  const tenure: Tenure = props.record
  const { t } = useTranslation()

  const tenureAttributes: Array<SearchDetails> = registrar.getTenureAttributes(tenure, true)
  const documentLink: DocumentLink = registrar.getDocumentLink?.(tenure, 'image')
  const docIdentifier: string = tenure.docIdentifier

  return (
    <>
      <DetailRow label={'common.document_identifier'} value={docIdentifier} span={!documentLink.url} />
      {documentLink.url && <a href={documentLink.url} target="_blank" className="tenure-search-details__document-link">{documentLink.label ? t(documentLink.label) : t('links.view_pdf')}</a>}
      <DetailRow label={'common.recorder'} value={<RecorderLink registrar={registrar} />} icon="small" />

      {tenureAttributes.map((attr: Object, i: number) =>
        <DetailRow key={`tenure-attribute-${i}`} label={attr.label} value={getValueFromAttribute(attr, registrar)} />
      )}
    </>
  )
}

TenureSearchDetails.propTypes = {
  registrar: PropTypes.shape({
    getTenureAttributes: PropTypes.func.isRequired,
    getDocumentLink: PropTypes.func
  }).isRequired,
  record: PropTypes.shape({
    artifact: PropTypes.shape({
      info: PropTypes.shape({
        title: PropTypes.string
      })
    }).isRequired
  }).isRequired
}

export default TenureSearchDetails
