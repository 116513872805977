import React from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import './DocumentSummary.scss'

import { DetailRow, RecorderLink, Divider } from '../index.js'
import { getValueFromAttribute } from '../../../helpers'

import type { DocumentLink } from '../../../classes/DocumentLink'
import type { SearchDetails } from '../../../classes/SearchDetails'

const DocumentSummary = (props) => {
  const { record, registrar } = props
  const { t } = useTranslation()

  const tenureAttributes: Array<SearchDetails> = registrar.getTenureAttributes(record)
  const documentImageLink: DocumentLink = registrar.getDocumentLink(record, 'image')
  const documentLink: DocumentLink = registrar.getDocumentLink(record)
  const docIdentifier: string = record.docIdentifier

  return (
    <section className="document-summary">
      <header className="document-summary__header">
        <motion.h3 className="document-summary__id-label">{t('common.document_identifier')}</motion.h3>
        <motion.h3 className="document-summary__id-value">{docIdentifier}</motion.h3>
        {documentImageLink.url && <a className="document-summary__link" href={documentImageLink.url} target="_blank">{documentImageLink.label ? t(documentImageLink.label) : t('links.view_pdf')}</a>}
      </header>

      <Divider />
      <DetailRow
        className="document-summary__recorder-label"
        label={'common.recorder'}
        value={<RecorderLink registrar={registrar} />}
        action={documentLink.url && <a className="document-summary__link" href={documentLink.url} target="_blank">{documentLink.label ? t(documentLink.label) : t('links.learn_more')}</a>}
      />

      <Divider />
      {tenureAttributes.map((attr: SearchDetails, i: number) =>
        <DetailRow key={i} label={attr.label} value={getValueFromAttribute(attr, registrar)} action={attr.action} />
      )}
    </section>
  )
}

DocumentSummary.propTypes = {
  registrar: PropTypes.shape({
    getTenureAttributes: PropTypes.func.isRequired,
    getDocumentLink: PropTypes.func.isRequired
  }),
  record: PropTypes.object
}

DocumentSummary.defaultProps = {
  record: {}
}

export default DocumentSummary
