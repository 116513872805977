import React, { ReactNode } from 'react'
import Registrar from '../classes/Registrar'
import type { SearchDetails } from '../classes/SearchDetails'

const getValueFromAttribute = (attr: SearchDetails, registrar: Registrar): ReactNode | Array<ReactNode> => {
  if (attr.label === 'common.Related') {
    return attr.value.map((relatedDoc: Object<{ Number: string }>, i: number): ReactNode => (
      <span key={`related-doc-${i}`}>
        <a href={`/search${registrar.path}/${relatedDoc.Number}`}>{relatedDoc.Number}</a>
      </span>
    ))
  }

  return attr.value
}

export default getValueFromAttribute
