/* eslint-disable no-multi-spaces */
import { Party, Record, SpatialUnit, Tenure } from '../Record'
import type { SearchDetails } from '../SearchDetails'
import type { DocumentLink } from '../DocumentLink'
import type { PartyGroup } from '../PartyGroup'

import getPartyRoles from './helpers/getPartyRoles'
import getPartyName from './helpers/getPartyName'
import getDocumentLink from './helpers/getDocumentLink'
import getLocationSearchDetails from './helpers/getLocationSearchDetails'

export default class Registrar {
  // Constants
  path: string            // The url path to display this registrar at
  url: string             // The home URL for the registrar
  mlgURL: string          // A link to learn more about this Registrars
  floAddress: string      // The FLO address the registrar is using to publish
  oipd: string            // The OIP address the registrar is using to publish
  namespace: string       // The namespace the registrar is using for its records

  // Copy
  // For tileName, featuredLink, featuredName, displayName, headerName, recorderName and description: Both translation
  // keys and copy are possible, the translation keys are preferred however.
  displayName: string     // Translation key for the name that is displayed in the header on the RecordSummary View
  featuredName: string    // Translation key for the name that is displayed on the County sub-homepage
  featuredLink: string    // Translation key for the name in the link that is displayed at the bottom of the County sub-homepage
  tileName: string        // Translation key for the name to display on the tile on the homepage
  headerName: string      // Translation key for the name that is displayed in the header on the Search View
  recorderName: string    // Translation key for the name that is displayed in the Registrar section on the RecordSummary view
  description: string     // Translation key for the description that will be displayed on the County sub-homepage
  logo: string            // The logo for the registrar
  featuredImage: string   // The image to be featured for more context about the Registrar

  // Class bindings (optional)
  // Assign specific classes to this registrar for customized methods. Defaults to base classes as below, if not specifified
  Tenure: Tenure
  Party: Party
  Record: Record
  SpatialUnit: SpatialUnit

  // Alternative host (optional)
  // This alternative host is used in development. When a registrar is selected, a dropdown menu will be
  // rendered to change the environment that is used to search and redirect. The main search will always
  // only search in the default oipd. When no altHost options are added, the default oip options will be
  // used.
  altHost: Object<{
    floExplorer: string,  // The FLO explorer address
    floAddress: string,   // The FLO address the registrar is using to publish
    oipd: string          // The OIP address the registrar is using to publish
  }>

  constructor () {
    this.floExplorer = process.env.ACTUM_DEFAULT_FLO_EXPLORER
    this.oipd = process.env.ACTUM_DEFAULT_OIP_HOST

    this.Tenure = Tenure
    this.Party = Party
    this.Record = Record
    this.SpatialUnit = SpatialUnit
  }

  // Defaults
  getLocationSearchDetails = (spatialUnit: SpatialUnit): Array<SearchDetails> => getLocationSearchDetails(this, spatialUnit)
  getDocumentLink = (tenure: Tenure, type?: string): DocumentLink => getDocumentLink(this, tenure, type)
  getMapLink = (): string => null
  getPartyName = (party: Party): string => getPartyName(this, party)
  getPartyRoles = (tenure: Tenure): Array<PartyGroup> => getPartyRoles(this, tenure)
  getSpatialAttributes = (): Array<SearchDetails> => []
  getTenureAttributes = (tenure: Tenure, compact?: boolean): Array<SearchDetails> => []
}
