export getRecord from './get-record'
export getValueFromAttribute from './get-value-from-attribute'

export function activeRegistrarKeys(Registrars) {
  const keys = Object.keys(Registrars)
  const hiddenKeys =
    process.env.ACTUM_PRIVATE_REGISTRARS
    ? JSON.parse(process.env.ACTUM_PRIVATE_REGISTRARS)
    : []
  if (hiddenKeys.length === 0
      || process.env.ACTUM_INCLUDE_PRIVATE_REGISTRARS === 'true') {
    return keys
  } else {
    return keys.filter((key) => !hiddenKeys.includes(key))
  }
}