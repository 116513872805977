import React from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import useOIPSearch from '../../../hooks/mutations/useOIPSearch'
import RecordDetails from '../RecordDetails'
import BlockchainProofs from '../BlockchainProofs'
import { TabController, RegistrarHeader } from '../../library'
import LoadingCard from './LoadingCard'
import ArrowLeftLong from '../../../../public/assets/svgs/arrow-left-long.svg'
import './RecordSummary.scss'
import Record from '../../../classes/Record'

const RecordSummary = props => {
  const { bHasSearchResults, id, registrar } = props
  const history = useHistory()
  const searchRecords = useOIPSearch(id, {}, registrar)
  const { t } = useTranslation()

  // Check if the record is available; Sometimes it only returns a list of records that are related.
  const getRecordFromResults = (txid: number): Record | null => {
    const record: Record = searchRecords?.data?.results?.find((record: Record) => record.artifact.info.title === txid)
    if (!record) return null

    return record
  }

  const record = getRecordFromResults(id)

  return (
    <div className="record-summary">
      <RegistrarHeader
        className="record-summary__header"
        registrar={registrar}
      />

      <button onClick={history.goBack} className="link-button">
        <span id="record-details-back"><ArrowLeftLong />{t('search.back_to_results')}</span>
      </button>

      {!searchRecords.isLoading && (
        <motion.div
          style={{ pointerEvents: 'auto' }}
          className="card"
          id="record-root"
          layoutId={id}
        >
          {record === null && t('search.no_recording_found', { txid: id })}
          {record && <>
            <TabController tabs={[
              {
                label: 'record.record_summary',
                content: <RecordDetails record={record} registrar={registrar} />
              },
              {
                label: 'record.blockchain_proofs',
                content: <BlockchainProofs record={record} registrar={registrar} />
              }
            ]} />
          </>}
        </motion.div>
      )}

      {searchRecords.isLoading && <LoadingCard style={{ marginTop: bHasSearchResults ? '0' : '3em' }} />}
    </div>
  )
}

RecordSummary.propTypes = {
  bHasSearchResults: PropTypes.bool,
  id: PropTypes.string,
  registrar: PropTypes.object.isRequired
}

export default RecordSummary
