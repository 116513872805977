import React from 'react'
import { useTranslation } from 'react-i18next'
import { HomeSearch, RegistrarTile, PoweredBy } from '../../library'
import { Link } from 'react-router-dom'

import { activeRegistrarKeys } from "../../../helpers";
import * as Registrars from '../../../registrars'

import './Homepage.scss'

export default function Homepage ({ record, registrar }) {
  const { t } = useTranslation()

  const RegistrarTiles = activeRegistrarKeys(Registrars).map(key => {
    const registrar = Registrars[key]
    return <RegistrarTile key={key} registrar={registrar} />
  })

  return (
    <main id='homepage-container'>
      <section id='search-section'>
          <h1>{t('home.header')}</h1>
          <HomeSearch />
          <Link to='/search'><p>{t('links.advanced_search')}</p></Link>
      </section>
      <section id='registrar-section'>
        <h2>{t('home.learn_about_projects')}</h2>
        <section id='registrars'>
          {RegistrarTiles}
        </section>
      </section>
      <section id='about' className='card' style={{ margin: '0 1em 0 1em' }}>
        <div>
          <p>{t('home.actum_explainer')}</p>
          <a href='https://mediciland.com'>{t('links.learn_more')}</a>
        </div>
      </section>
      <PoweredBy />
    </main>
  )
}
