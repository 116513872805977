import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import './styles.scss'
import Checkbox from '../Checkbox'

const SearchFilter = props => {
  const { className, onChange, options } = props
  const [filter, setFilter] = useState(options)

  const handleChange = (checked: boolean, value: string) => {
    const updatedFilter = filter.map(option => option.value === value ? { ...option, checked } : option)

    setFilter(updatedFilter)
    onChange(updatedFilter)
  }

  return (
    <div className={clsx('search-filter', className)}>
      {options.map(({ value, label, checked }) =>
        <Checkbox key={value} onChange={handleChange} value={value} label={label} checked={checked} />
      )}
    </div>
  )
}

SearchFilter.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    checked: PropTypes.bool
  })).isRequired
}

SearchFilter.defaultProps = {
  className: ''
}

export default SearchFilter
