import React, { ReactNode } from 'react'
import { is } from 'ramda'
import dayjs from 'dayjs'
import i18next from 'i18next'

import NewYorkCityRegistrar from '../index'
import NewYorkCityTenure from '../classes/NewYorkCityTenure'
import type { SearchDetails } from '../../../../../classes/SearchDetails'
import type { DocumentLink } from '../../../../../classes/DocumentLink'

export default function getTenureAttributes (registrar: NewYorkCityRegistrar, oipTenure: NewYorkCityTenure, compact?: boolean): Array<SearchDetails> {
  const attributes: Array<SearchDetails> = []

  const docType = oipTenure.getCustomProperty('DOC_TYPE')
  docType && attributes.push({ label: 'common.document_type', value: docType })

  const recordedOn = oipTenure.getCustomProperty('RECORDED_DATETIME')
  recordedOn && attributes.push({ label: 'usa.ny.nyc.recorded_on', value: dayjs(recordedOn).format('M/D/YYYY H:mm:ss') })

  const docDate = oipTenure.getCustomProperty('DOCUMENT_DATE')
  docDate && attributes.push({ label: 'common.document_date', value: docDate })

  const crfn = oipTenure.getCustomProperty('CRFN')
  crfn && attributes.push({ label: 'usa.ny.nyc.crfn', value: crfn })

  const docIdRef = oipTenure.getCustomProperty('DOC_ID_REF')
  const documentLink: DocumentLink = registrar.getDocumentLink(oipTenure, 'ref')
  const DocIdElement = ({ docId }) => <a style={{ fontSize: 12, width: '100%', display: 'block' }} href={docId}>{docId}</a>
  const docIdValue = is(Array, docIdRef) ? docIdRef.map((docId: string): ReactNode => <DocIdElement docId={docId} />) : [<DocIdElement docId={docIdRef} />]
  docIdRef && docIdRef.length && attributes.push({
    label: 'usa.ny.nyc.docIdRef',
    value: docIdValue.slice(0, 5),
    action: (docIdValue.length > 5 && documentLink.url) && <a className="document-summary__link" href={documentLink.url} target="_blank">{documentLink.label ? i18next.t(documentLink.label) : i18next.t('links.learn_more')}</a>
  })

  return compact ? attributes.slice(0, 3) : attributes
}
