import NewYorkCityRegistrar from '../index'
import NewYorkCityTenure from '../classes/NewYorkCityTenure'
import type { DocumentLink } from '../../../../../classes/DocumentLink'

const getDocumentLink = (registrar: NewYorkCityRegistrar, tenure: NewYorkCityTenure, type?: 'image' | 'ref'): DocumentLink => {
  if (type === 'image') return ({ url: `https://a836-acris.nyc.gov/DS/DocumentSearch/DocumentImageView?doc_id=${tenure.docIdentifier}`, label: 'usa.ny.nyc.view_document_image' })
  if (type === 'ref') return ({ url: `https://a836-acris.nyc.gov/DS/DocumentSearch/DocumentDetail?doc_id=${tenure.docIdentifier}`, label: 'usa.ny.nyc.view_all_references' })
  return ({ url: `https://a836-acris.nyc.gov/DS/DocumentSearch/DocumentDetail?doc_id=${tenure.docIdentifier}`, label: 'usa.ny.nyc.view_recording_details' })
}

export default getDocumentLink
