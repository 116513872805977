import React, { useContext, useState } from 'react'

const ValueContext = React.createContext([])
export const useSearchValue = () => useContext(ValueContext)

export default function SearchValueProvider ({ children }) {
  const searchValueState = useState('')

  return <ValueContext.Provider value={searchValueState}>
    {children}
  </ValueContext.Provider>
}
