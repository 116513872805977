import dayjs from 'dayjs'
import RwandaRegistrar from '../index'
import RwandaTenure from '../classes/RwandaTenure'

export default function getTenureAttributes (registrar: RwandaRegistrar, oipTenure: RwandaTenure, compact?: boolean) {
  const attributes = []

  const recordedOn = oipTenure.getCustomProperty('timestamp')
  recordedOn && attributes.push({ label: 'common.approval_date', value: dayjs(parseFloat(recordedOn)).format('DD/MMM/YYYY H:mm:ss') })

  const upi = oipTenure.getCustomProperty('upi')
  upi && attributes.push({ label: 'rwanda.UPI', value: upi })

  const type = oipTenure.getCustomProperty('type')
  type && attributes.push({ label: 'common.Type', value: oipTenure.type })

  const registrarSignature = oipTenure.getCustomProperty('dataSignature')
  registrarSignature && attributes.push({ label: 'common.RegistrarSignature', value: registrarSignature })

  const signature = oipTenure.getArtifactProperty('signature')
  signature && attributes.push({ label: 'common.signature', value: signature })

  return compact ? attributes.slice(0, 3) : attributes
}
