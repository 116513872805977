import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { motion, AnimatePresence } from 'framer-motion'
import { useTranslation } from 'react-i18next'

import { SearchResult, Pagination, FeaturedRegistrar, RegistrarHeader, Select } from '../../library'
import LoadingList from './LoadingList'
import './SearchList.scss'

const { NODE_ENV, ACTUM_DEFAULT_OIP_HOST } = process.env

const SearchList = (props) => {
  const { records, total, currentPage, handlePageChange, registrar, query, loading, handleOIPHostChange } = props
  const { t } = useTranslation()
  const list = useRef(null)
  const oipHost = localStorage.getItem('oipHost')

  const motionConfig = {
    initial: { x: 100, opacity: 0 },
    animate: { x: 0, opacity: 1 },
    transition: { ease: 'easeOut', duration: 0.2 },
    exit: { x: -300, opacity: 0 }
  }

  const getOIPHostOptions = (): Array<{ label: string, value: string }> => {
    return [
      { label: ACTUM_DEFAULT_OIP_HOST, value: ACTUM_DEFAULT_OIP_HOST },
      ...(() => {
        if (registrar.altHost) {
          return [{
            label: registrar.altHost.oipd,
            value: registrar.altHost.oipd
          }]
        }
        return []
      })()
    ]
  }

  return (
    <div className="search-list">
      <AnimatePresence>
        {registrar && (
          <>
            <div className="search-list__header">
              <RegistrarHeader
                registrar={registrar}
                motionConfig={motionConfig}
              />

              {NODE_ENV === 'development' && (
                <motion.div
                  className="search-list__dropdown-container"
                  key="search-list-dropdown-container"
                  {...motionConfig}
                >
                  <Select
                    selected={oipHost}
                    className="search-list__dropdown"
                    optionListClassName="search-list__option-list"
                    handleChange={handleOIPHostChange}
                    options={getOIPHostOptions()}
                  />
                </motion.div>
              )}
            </div>

            {!total && <FeaturedRegistrar registrar={registrar} />}
          </>
        )}

        {!registrar && (
          <div className="search-list__header">
            <motion.h2
              className="search-list__registrar"
              layerId="record-header"
              {...motionConfig}
            >
              {t('search.blockchain_records')}
            </motion.h2>
          </div>
        )}
      </AnimatePresence>

      <motion.div className="search-list__records-container">
        {loading && <LoadingList />}
        {!loading && (
          <>
            {total > 0 && query?.trim() !== '' && (
              <>
                <motion.ul className="search-list__records" ref={list}>
                  {records && records.map((record, i) =>
                    <SearchResult key={`search-result-${i}`} record={record} />
                  )}

                </motion.ul>
                <Pagination
                  currentPage={currentPage}
                  rowsPerPage={10}
                  handlePageChange={(newPage) => {
                    handlePageChange(newPage)
                    list.current.scrollTop = 0
                  }}
                  total={total}
                  loading={loading}
                />
              </>
            )}

            {!total && (
              <h3>
                {query?.trim() !== '' && `No records found with the search "${query}"`}
                {query?.trim() === '' && 'Enter a search to begin'}
              </h3>
            )}
          </>
        )}
      </motion.div>
    </div>
  )
}

SearchList.propTypes = {
  currentPage: PropTypes.number,
  loading: PropTypes.bool,
  handleOIPHostChange: PropTypes.func.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  query: PropTypes.string,
  records: PropTypes.array,
  registrar: PropTypes.object,
  total: PropTypes.number
}

export default SearchList
