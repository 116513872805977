import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import './Option.scss'

const Option = props => {
  const { className, onClick, value, label, icon, dropdownHasIcons } = props

  return (
    <li
      className={clsx('option', className)}
      onClick={() => onClick(value)}
    >
      {icon && <img className="option__icon" src={icon} alt={'Option image'}/>}
      <span className={clsx('option__label', dropdownHasIcons && !icon && 'option__label--no-icon')}>{label}</span>
    </li>
  )
}

Option.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.node,
  dropdownHasIcons: PropTypes.bool
}

Option.defaultProps = {
  className: '',
  dropdownHasIcons: false
}

export default Option
