import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const Skeleton = props => <div {...props} className={clsx('skeleton', props.className)} />

Skeleton.propTypes = {
  className: PropTypes.string
}

export default Skeleton
