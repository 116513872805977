import CarbonCountyRegistrar from '../index'
import { SpatialUnit } from '../../../../../classes/Record'
import type { SearchDetails } from '../../../../../classes/SearchDetails'

export default function getLocationSearchDetails (registrar: CarbonCountyRegistrar, spatialUnitRecord: SpatialUnit): Array<SearchDetails> {
  let compactString = ''

  /* See if we should add `Township` */
  const township = spatialUnitRecord?.artifact?.details?.attrs?.custom?.Township
  if (township) {
    compactString += ` T${township}`
  }

  /* See if we should add `Section` */
  const section = spatialUnitRecord?.artifact?.details?.attrs?.custom?.Section
  if (section) {
    compactString += ` S${section}`
  }

  /* See if we should add `Range` */
  const range = spatialUnitRecord?.artifact?.details?.attrs?.custom?.Range
  if (range) {
    compactString += ` R${range}`
  }

  /* See if we should add Descriptioon */
  const description = spatialUnitRecord?.artifact?.details?.attrs?.custom?.Description
  if (description) {
    compactString += ` ${description}`
  }

  return [{ value: compactString, label: 'common.parcel_#' }]
}
