import NewYorkCityRegistrar from '../index'
import NewYorkCityTenure from '../classes/NewYorkCityTenure'
import NewYorkCityParty from '../classes/NewYorkCityParty'
import type { PartyGroup } from '../../../../../classes/PartyGroup'
import { getRecord } from '../../../../../helpers'

export default async (registrar: NewYorkCityRegistrar, tenure?: NewYorkCityTenure): Array<PartyGroup> => {
  const parties: Array<string> = tenure?.parties
  const groups = {}
  if (!parties) return []

  // Get all party details based on txid, and wait until all details from all parties have been retrieved
  const mappedParties: Array<NewYorkCityParty> = await Promise.all(parties.map(async ({ party: txid }) => getRecord(txid)))

  // For each party, create a group/category if it doesn't exist yet, determine the order of parties and push the parties into the array
  mappedParties.forEach((party: NewYorkCityParty) => {
    const role: string = party.role
    if (!groups[role]) groups[role] = { label: role?.toLowerCase(), parties: [], partyOrder: party.partyOrder }
    groups[role].parties.push(party)
  })

  // Map the parties into an array
  const groupsToBeSorted: Array<PartyGroup> = Object.values(groups)

  // Sort the groups, based on the PARTY_ORDER.
  // The order value is retrieved from the first party of each category (see ../classes/NewYorkCityParty.js)
  const groupsToBeReturned: Array<PartyGroup> = groupsToBeSorted.sort((curr: PartyGroup, prev: PartyGroup): number => {
    if (curr.partyOrder > prev.partyOrder) return 1
    if (curr.partyOrder < prev.partyOrder) return -1
    return 0
  })

  // Return Party Roles
  return groupsToBeReturned
}
