import React, { Suspense } from 'react'
import SuspenseProvider from './SuspenseProvider'
import ReactRouterProvider from './ReactRouterProvider'
import ReactQueryProvider from './ReactQueryProvider'
import SearchValueProvider from './SearchValueProvider'

/**
 * Add new providers here. They  will render in the order they appear in this array.
 */
const Providers = [
  SuspenseProvider,
  // ReduxProvider,
  ReactQueryProvider,
  ReactRouterProvider,
  SearchValueProvider
]

const AllProviders = ({ Providers, children }) => {
  const Provider = Providers.shift()
  if (!Provider) return children
  return <Provider>
    {AllProviders({ Providers, children })}
  </Provider>
}

export default function RootProvider ({ children }) {
  return <Suspense fallback={null}>
    <AllProviders Providers={Providers}>
      {children}
    </AllProviders>
  </Suspense>
}
