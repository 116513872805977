import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import SearchIcon from '../../../../public/assets/svgs/search.svg'
import './HomeSearch.scss'
import { useSearchValue } from '../../providers/SearchValueProvider'

export default function HomeSearch () {
  const { t } = useTranslation()
  const history = useHistory()
  const [value, setValue] = useSearchValue()

  function handleSubmit () {
    history.push('/search')
  }

  function handleChange (e) {
    setValue(e.target.value)
  }

  function handleKeyDown (e) {
    if (e.which === 13) {
      // pressed 'enter'
      handleSubmit()
    }
  }

  return (
    <div id='searchbar'>
      <SearchIcon className='icon' />
      <input
        placeholder={t('home.search')}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      <button onClick={handleSubmit}>
        {t('common.search')}
      </button>
    </div>
  )
}

HomeSearch.propTypes = {
  onSearchValueChange: PropTypes.func
}
