import Registrar from '../../classes/Registrar'
import RwandaTenure from './classes/RwandaTenure'
import FeatureImage from './feature.jpg'
import Logo from './rwanda-coat-of-arms.png'

import getTenureAttributes from './helpers/getTenureAttributes'

export default class RwandaRegistrar extends Registrar {
  constructor () {
    super()

    // Constants
    this.floAddress = 'FByDstiykihshgimEBNPrdDBL5gg9iriHZ'
    this.path = '/rwanda/rlmua/land'
    this.mlgURL = 'https://mediciland.com'
    this.url = 'https://www.environment.gov.rw/'
    this.namespace = 'rlmua.rwanda/1.0.0'

    // Copy
    this.tileName = 'rwanda.tileName'
    this.displayName = 'rwanda.displayName'
    this.featuredName = 'rwanda.featuredName'
    this.featuredLink = 'rwanda.featuredLink'
    this.headerName = 'rwanda.headerName'
    this.recorderName = 'rwanda.recorderName'
    this.description = 'rwanda.description'
    this.logo = Logo
    this.featuredImage = FeatureImage

    // Class bindings
    this.Tenure = RwandaTenure

    // Overrides
    this.altHost = {
      floAddress: process.env.ACTUM_RWANDA_FLO_ADDRESS_ALT,
      floExplorer: process.env.ACTUM_RWANDA_FLO_EXPLORER_ALT,
      oipd: process.env.ACTUM_RWANDA_OIP_HOST_ALT
    }
  }

  // Helper Functions to extract information specific to the Registrar
  getTenureAttributes = (tenure: RwandaTenure, compact?: boolean) => getTenureAttributes(this, tenure, compact)
}
