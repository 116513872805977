import React from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

export default function ReactRouterProvider ({ children }) {
  return <Router history={history}>
    {children}
  </Router>
}
