import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { motion, AnimateSharedLayout } from 'framer-motion'

import './TabController.scss'

/**
 * Control switching between multiple tabs
 * @param {Array.<Object>} props.tabs - An array containing tab objects with the following format
 * Example Tabs: [
 *   { label: 'Tab 1', content: <ComponentOne /> },
 *   { label: 'Tab 2', content: <ComponentTwo /> }
 *  ]
 */
const spring = {
  type: 'spring',
  stiffness: 500,
  damping: 30
}

const TabController = ({ tabs, variant, layout }) => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState(0)
  const indicator = useRef(null)
  const content = tabs[activeTab].content || null

  return (
    <div className={clsx('tab-controller', layout)}>
      <AnimateSharedLayout>
        <ul className={`tabs-${variant}`}>
          {tabs.map((tab, index) =>
            <motion.li
              className="tab-controller__item"
              animate={{ color: index === activeTab ? '#000' : '#80808a' }}
              onClick={() => setActiveTab(index)}
              key={`tab-${index}`}
            >
              {t(tab.label)}
              {index === activeTab && <motion.div
                key={index}
                layoutId="outline"
                className="outline"
                initial={{ x: 0, y: 0 }}
                animate={{ backgroundColor: '#008FFF' }}
                transition={spring}
                ref={indicator}
              />}
            </motion.li>
          )}
        </ul>
      </AnimateSharedLayout>
      <div id="tabcontroller-content">
        {content || <h3>No content to display.</h3>}
      </div>

    </div>
  )
}

TabController.defaultProps = {
  tabs: [],
  variant: 'horizontal',
  layout: 'layout-1'
}

TabController.propTypes = {
  tabs: PropTypes.array,
  variant: PropTypes.string,
  layout: PropTypes.string
}

export default TabController
