import Registrar from '../../../../classes/Registrar'
import Record, { SpatialUnit, Tenure } from '../../../../classes/Record'

import getTenureAttributes from './helpers/getTenureAttributes'
import getSpatialAttributes from './helpers/getSpatialAttributes'
import getMapLink from './helpers/getMapLink'
import getLocationSearchDetails from './helpers/getLocationSearchDetails'
import FeatureImage from './feature.jpg'
import Logo from './carbon.gif'

export default class CarbonCountyRegistrar extends Registrar {
  constructor () {
    super()

    // Constants
    this.floAddress = 'FRLp5g1SioUnXbmB93yJscRUApXiGviWBG'
    this.path = '/usa/wy/carbon'
    this.mlgURL = 'https://mediciland.com'
    this.url = 'https://www.carbonwy.com/943/Land-Records'
    this.namespace = 'TylerTech'

    // Copy
    this.tileName = 'Carbon County, WY'
    this.displayName = 'Carbon County'
    this.featuredName = 'Carbon County Blockchain Records'
    this.featuredLink = 'in Carbon County'
    this.headerName = 'Carbon County Blockchain Records'
    this.recorderName = 'Carbon County'
    this.description = 'MLG supports Carbon County with an immutable archive of land records on blockchain, which can be viewed through a convenient web portal.'
    this.logo = Logo
    this.featuredImage = FeatureImage
  }

  getTenureAttributes = (tenure: Tenure, compact?: boolean) => getTenureAttributes(this, tenure, compact)
  getSpatialAttributes = (spatialUnit: SpatialUnit) => getSpatialAttributes(this, spatialUnit)
  getLocationSearchDetails = (spatialUnit: SpatialUnit) => getLocationSearchDetails(this, spatialUnit)
  getMapLink = (record: Record) => getMapLink(this, record)
}
