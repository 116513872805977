import { Party } from '../../../../../classes/Record'
import type { NewYorkCityPartyInterface } from './interfaces/NewYorkCityParty.interface'

export default class NewYorkCityParty extends Party implements NewYorkCityPartyInterface {
  get role () {
    return this.artifact?.details?.attrs?.custom?.PARTY_TYPE
  }

  get partyOrder () {
    return this.artifact?.details?.attrs?.custom?.PARTY_ORDER
  }
}
