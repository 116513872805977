import Registrar from '../../../../classes/Registrar'
import Record, { Party, SpatialUnit, Tenure } from '../../../../classes/Record'

import getTenureAttributes from './helpers/getTenureAttributes'
import getPartyName from './helpers/getPartyName'
import getSpatialAttributes from './helpers/getSpatialAttributes'
import getMapLink from './helpers/getMapLink'
import getLocationSearchDetails from './helpers/getLocationSearchDetails'
import FeatureImage from './feature.jpg'
import Logo from './teton.png'

export default class TetonCountyRegistrar extends Registrar {
  constructor () {
    super()

    // Constants
    this.floAddress = 'FKvUqRc8qdJwMpy5Q22fLJ9qowQtbKW6aL'
    this.path = '/usa/wy/teton'
    this.mlgURL = 'https://mediciland.com'
    this.url = 'http://www.tetonwyo.org/299/Records'
    this.namespace = 'mlg.teton'

    // Copy
    this.tileName = 'Teton County, WY'
    this.displayName = 'Teton County'
    this.featuredName = 'Teton County Blockchain Records'
    this.featuredLink = 'in Teton County'
    this.headerName = 'Teton County Blockchain Records'
    this.recorderName = 'Teton County'
    this.description = 'MLG supports Teton County with an immutable archive of land records on blockchain, which can be viewed through a convenient web portal.'
    this.logo = Logo
    this.featuredImage = FeatureImage
  }

  // Helper Functions to extract information specific to the Registrar
  getTenureAttributes = (tenure: Tenure, compact?: boolean) => getTenureAttributes(this, tenure, compact)
  getPartyName = (party: Party) => getPartyName(this, party)
  getSpatialAttributes = (spatialUnit: SpatialUnit) => getSpatialAttributes(this, spatialUnit)
  getLocationSearchDetails = (spatialUnit: SpatialUnit) => getLocationSearchDetails(this, spatialUnit)
  getMapLink = (record: Record) => getMapLink(this, record)
}
