import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'

import SearchIcon from '../../../../public/assets/svgs/search.svg'
import './SearchInput.scss'

const hasContentAnimate = {
  top: -10,
  x: 10,
  padding: '2px',
  fontSize: '10px'
}

const noContentAnimate = {
  y: 0,
  x: 18,
  fontSize: '14px'
}

const SearchInput = props => {
  const { onChange, onClick, value, onKeyDown } = props
  const { t } = useTranslation()
  const [focused, setFocused] = useState(false)

  return (
    <div className="searchbar-outline">
      <motion.label
        className="searchbar-outline__label"
        animate={value !== '' || focused ? hasContentAnimate : noContentAnimate}
      >
        {t('home.search')}
      </motion.label>
      <input
        className="searchbar-outline__input"
        placeholder={t('home.search')}
        value={value}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      <SearchIcon className="searchbar-outline__icon" onClick={onClick} />
    </div>
  )
}

SearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default SearchInput
