import React, { ReactNode, PropsWithChildren } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Registrar from '../../../classes/Registrar'
import { SpatialUnit } from '../../../classes/Record'
import { DetailRow, Skeleton } from '../index.js'
import useOIPRecord from '../../../hooks/useOIPRecord'

const LocationSummary = (props: PropsWithChildren): ReactNode => {
  const { t } = useTranslation()
  const txid: string = props.txid
  const registrar: Registrar = props.registrar
  const record: SpatialUnit = useOIPRecord(txid, registrar)

  if (!record) return <Skeleton />

  const spatialAttributes = registrar.getSpatialAttributes(record)
  const mapLink = registrar.getMapLink(record)

  return (
    <div className="location-summary">
      <div>
        {!spatialAttributes.length && t('location.no_info', { txid })}
        {!!spatialAttributes.length && spatialAttributes.map((attr, i) =>
          <DetailRow key={`spatial-attribute-${i}`} label={attr.label} value={attr.value} sizing={mapLink ? 'secondary' : 'primary'} />
        )}
      </div>
      {mapLink && <a href={mapLink}>View Large Map</a>}
    </div>
  )
}

LocationSummary.propTypes = {
  txid: PropTypes.string,
  registrar: PropTypes.object
}

export default LocationSummary
