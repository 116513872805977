import React, { PropsWithChildren, ReactNode } from 'react'
import PropTypes from 'prop-types'
import Record, { SpatialUnit } from '../../../../classes/Record'
import Registrar from '../../../../classes/Registrar'
import type { SearchDetails } from '../../../../classes/SearchDetails'

import useOIPRecord from '../../../../hooks/useOIPRecord'
import { DetailRow } from '../../index'

import './LocationSearchDetails.scss'

const LocationSearchDetails = (props: PropsWithChildren): ReactNode | null => {
  const registrar: Registrar = props.registrar
  const record: Record = props.record
  const locations: Array<string> = record?.artifact?.details?.spatialUnits
  const locRecord: SpatialUnit = useOIPRecord(locations?.[0])

  if (record.artifact.subtype !== 'tenure') return null

  const locationSearchDetails: Array<SearchDetails> = registrar.getLocationSearchDetails(locRecord)

  return locationSearchDetails.map((row: SearchDetails, i: number) => {
    if (row.label === null) return <DetailRow key={`loc-search-row-${i}`} empty />
    if (!row.value) return null

    return <DetailRow key={`loc-search-row-${i}`} label={row.label} value={row.value} />
  })
}

LocationSearchDetails.propTypes = {
  registrar: PropTypes.shape({
    path: PropTypes.string.isRequired,
    getLocationSearchDetails: PropTypes.func
  }).isRequired,
  record: PropTypes.shape({
    artifact: PropTypes.shape({
      info: PropTypes.shape({
        title: PropTypes.string
      }),
      details: PropTypes.shape({
        spatialUnits: PropTypes.arrayOf(PropTypes.string)
      })
    })
  }).isRequired
}

export default LocationSearchDetails
