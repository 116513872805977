import NewYorkCityRegistrar from '../index'
import NewYorkCitySpatialUnit from '../classes/NewYorkCitySpatialUnit'
import type { SearchDetails } from '../../../../../classes/SearchDetails'

export default function getSpatialAttributes (registrar: NewYorkCityRegistrar, spatialUnitRecord: NewYorkCitySpatialUnit): Array<SearchDetails> {
  const attributes: Array<SearchDetails> = []
  const customAttrs = spatialUnitRecord.customProperties

  if (customAttrs) {
    const { STREET_NUMBER, STREET_NAME, ADDR_UNIT, BLOCK, BOROUGH, LOT, PROPERTY_TYPE } = customAttrs

    BOROUGH && attributes.push({ label: 'common.Borough', value: BOROUGH })
    BLOCK && attributes.push({ label: 'common.Block', value: BLOCK })
    LOT && attributes.push({ label: 'common.Lot', value: LOT })
    PROPERTY_TYPE && attributes.push({ label: 'common.PropertyType', value: PROPERTY_TYPE })
    if (STREET_NUMBER || STREET_NAME) attributes.push({ label: 'common.PropertyAddress', value: `${STREET_NUMBER} ${STREET_NAME}` })
    ADDR_UNIT && attributes.push({ label: 'common.UnitNumber', value: ADDR_UNIT })
  }

  return attributes
}
