import axios from 'axios'
import { Party, Record, SpatialUnit, Tenure } from '../classes/Record'
import * as registrars from '../registrars'

const getRecord = async (txid: string): SpatialUnit | Party | Tenure | Record => {
  const oipHost: string = localStorage.getItem('oipHost')
  const response = await axios.get(`${oipHost}/oip042/record/get/${txid}`)

  const setRecordType = (rec: Object): SpatialUnit | Party | Tenure | Record => {
    if (!rec) return null

    for (const registrarName in registrars) {
      const registrar = registrars[registrarName]
      if (registrar.namespace === rec.artifact.details?.ns) {
        if (rec.artifact.subtype === 'spatialUnit') return new registrar.SpatialUnit(rec)
        if (rec.artifact.subtype === 'party') return new registrar.Party(rec)
        if (rec.artifact.subtype === 'tenure') return new registrar.Tenure(rec)

        return new registrar.Record(rec)
      }
    }

    return new Record(rec)
  }

  return setRecordType(response?.data?.results?.[0])
}

export default getRecord
