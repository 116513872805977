import React from 'react'

import { useTranslation } from 'react-i18next'

const Route404 = () => {
  const { t } = useTranslation()
  return <h1>{t('404.route_does_not_exist')}</h1>
}

export default Route404
