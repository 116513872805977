import React, { useState, useEffect, PropsWithChildren, ReactNode } from 'react'
import PropTypes from 'prop-types'

import './PartySearchDetails.scss'
import Registrar from '../../../../classes/Registrar'
import Record from '../../../../classes/Record'
import type { PartyGroup } from '../../../../classes/PartyGroup'

import { DetailRow } from '../../index'

const PARTIES_TO_DISPLAY = 2

const PartySearchDetails = (props: PropsWithChildren): ReactNode => {
  const [partyRoles, setPartyRoles] = useState([])
  const registrar: Registrar = props.registrar
  const record: Record = props.record

  useEffect(async () => {
    const roles = await registrar.getPartyRoles(record)
    setPartyRoles(roles)
  }, [record])

  return partyRoles.map((group: PartyGroup, i: number) => {
    const parties = group.parties
    const partyComponents = []

    if (!parties.length) return null

    partyComponents.push(registrar.getPartyName(parties[0]))
    if (parties.length === PARTIES_TO_DISPLAY) partyComponents.push(registrar.getPartyName(parties[1]))
    else if (parties.length > PARTIES_TO_DISPLAY) partyComponents.push(<span className="party-search-details__moreMsg" key={2}>{parties.length - 1} more</span>)

    return <DetailRow key={`party-row-${i}`} label={group.label} value={partyComponents} />
  })
}

PartySearchDetails.propTypes = {
  registrar: PropTypes.shape({
    getPartyRoles: PropTypes.func
  }).isRequired,
  record: PropTypes.object.isRequired
}

export default PartySearchDetails
