import { useQuery } from 'react-query'
import createQueryString from '../../utils/create-querystring'
import searchRecordsApi from '../../apis/oip/searchRecordsApi'
import createOIPTenureQuery from '../../helpers/createOIPTenureQuery'

import Registrar from '../../classes/Registrar'
import { Tenure } from '../../classes/Record'

import * as registrars from '../../registrars'

export default function useOIPSearch (
  query: string,
  options: Object,
  registrar: Registrar | Object,
  filter: Array<{ label: string, value: string, checked: boolean }>,
  oipHost: string
): Object {
  const recordsResult = useQuery(
    [query, options?.page, registrar?.namespace, filter, oipHost],
    async () => {
      const queryString = createQueryString(createOIPTenureQuery(query, registrar, filter))
      return await searchRecordsApi(queryString, options, localStorage.getItem('oipHost'))
    },
    {
      onError: (err, query: string) => {
        console.error(`Failed to search for: ${encodeURIComponent(createQueryString(query))}: ${err.message}`)
      },
      enabled: query !== '',
      keepPreviousData: false
    }
  )

  const mapResultsToTenureClass = (records: Array<Object>): Array<Tenure> => {
    if (!records) return records

    return records.map((record: Object) => {
      for (const registrarName in registrars) {
        const registrar = registrars[registrarName]
        if (registrar.namespace === record.artifact.details?.ns) {
          return new registrar.Tenure(record)
        }
      }

      return new Tenure(record)
    })
  }
  return {
    ...recordsResult,
    data: {
      ...recordsResult.data,
      results: mapResultsToTenureClass(recordsResult.data?.results)
    }

  }
}
